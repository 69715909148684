import React from 'react'
import { Typography } from '@material-ui/core'

import Layout from '../components/Layout'
import FooterHero from '../components/FooterHero'
import SEO from '../components/seo'
import HomeHero from '../components/HomeHero'

const IndexPage = () => (
  <Layout>
    <SEO
      keywords={[
        `it`,
        `application`,
        `solution`,
        `development`,
        `พัฒนาระบบ`,
        `พัฒนาเว็บไซต์`,
        `พัฒนาแอปมือถือ`,
      ]}
    />
    <HomeHero />
    <div>
      <Typography gutterBottom />
    </div>
    <FooterHero />
  </Layout>
)

export default IndexPage
