import React from 'react'
import { Grid, Typography, withStyles } from '@material-ui/core'
import ContactButton from '../ContactButton'

const footerStyles = () => ({
  container: {
    margin: '3rem 0px 0px',
    padding: '0px 0px 5rem',
    backgroundColor: `rgb(51, 85, 158)`,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 1280,
    margin: '0px auto',
    padding: '0px 1rem',
  },
  headline: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '2.5rem',
    lineHeight: '3rem',
    fontWeight: 300,
    marginTop: '5rem',
    marginBottom: '2rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    maxWidth: 820,
    color: 'rgb(255, 255, 255)',
  },
})

const FooterHero = ({ classes }) => (
  <div className={classes.container}>
    <Grid container alignItems="center" className={classes.root}>
      <Grid item xs={12}>
        <Grid container justify="center">
          <Typography
            align="center"
            variant="h4"
            gutterBottom
            className={classes.headline}
          >
            LET'S WORK TOGETHER ON YOUR NEXT PROJECT!
          </Typography>
        </Grid>
        <Grid container justify="center">
          <ContactButton />
        </Grid>
      </Grid>
    </Grid>
  </div>
)

export default withStyles(footerStyles)(FooterHero)
