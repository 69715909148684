import React from 'react'
import { Grid, Typography, withStyles } from '@material-ui/core'
import heroImage from '../../images/simon-abrams-286276-unsplash.jpg'

const styles = theme => {
  const mediaMaxWidthSM =
    '@media (max-width: ' + theme.breakpoints.values.sm + 'px)'
  return {
    root: {
      height: '60vh',
      background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${heroImage}) center center`,
      backgroundSize: 'cover',
      [mediaMaxWidthSM]: {
        height: '50vh',
      },
      opacity: 1,
    },
    headline: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '2.6rem',
      fontWeight: 700,
      maxWidth: '55%',
      color: 'rgb(255, 255, 255)',
      [mediaMaxWidthSM]: {
        maxWidth: '95%',
        fontWeight: 600,
        fontSize: '2.45rem',
      },
    },
    subHeadline: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '1.8rem',
      lineHeight: '2.4rem',
      fontWeight: 400,
      maxWidth: '55%',
      color: 'rgb(255, 255, 255)',
      [mediaMaxWidthSM]: {
        maxWidth: '85%',
        lineHeight: '1.7rem',
        fontSize: '1.3rem',
      },
    },
  }
}

const HomeHero = withStyles(styles)(({ classes }) => (
  <Grid container alignItems="center" className={classes.root}>
    <Grid item xs={12}>
      <Grid container justify="center">
        <Typography
          align="center"
          variant="h4"
          gutterBottom
          className={classes.headline}
        >
          Aginix Technologies
        </Typography>
      </Grid>
      <Grid container justify="center">
        <Typography
          align="center"
          variant="h6"
          gutterBottom
          className={classes.subHeadline}
        >
          We research & develop cutting edge technology, deliver awesomeness
          through agile methods and rapid application frameworks.
        </Typography>
      </Grid>
    </Grid>
  </Grid>
))

export default HomeHero
